<template lang="pug">
    article.expanded-items
        ui-paragraph.expanded-items-title(
            v-html="'На&nbsp;вопросы отвечает педиатр-иммунолог, доктор медицинских наук профессор Андрей Продеус'",
            component="p"
        )
        .data
            nav.expanded-items-nav
                button.nav-item(
                    v-for="(item, index) in interviewData",
                    :key="index",
                    @click="active = index",
                    :class="{ 'active': active === index }"
                )
                    article-icon.icon(src="hex_green")
                    span {{ index + 1 }}
            .question-answer(ref="scroll")
                ui-title.question(
                    v-html="current.question",
                    variant="subtitle-2",
                    component="p"
                )
                ui-paragraph.answer(v-html="current.answer")
            .image-wrap(aria-hidden="true")
                img.image.body(
                    src="../../../../../assets/images/articles/1/interview_body.svg",
                    alt="Иконка Интервью",
                    :style="style.body"
                )
                img.image.rec(
                    src="../../../../../assets/images/articles/1/interview_rec.svg",
                    alt="Иконка Интервью",
                    :style="style.rec"
                )
                article-icon.image-decor(src="hex", class="opacity-50 pulse")
</template>

<script>
import ArticleIcon from '../../../Icon.vue';
import UiTitle from '../../../../ui/Title.vue';
import UiParagraph from '../../../../ui/Paragraph.vue';

import interviewData from '../../../../../assets/data/articles/1/unique/interview.json';
import { getInertedMouse } from '../../../../../assets/js/utils';

export default {
    name: 'interview',
    components: {
        ArticleIcon,
        UiTitle,
        UiParagraph,
    },
    computed: {
        interviewData: () => interviewData,
        current() {
            return interviewData[this.active];
        },
        style() {
            const { x, y } = this.mouse;
            const bodyX = x * -1 * 6;
            const bodyY = y * -1 * 4;
            const recX = x * 6;
            const recY = y * 8;

            return {
                body: {
                    transform: `translate(${bodyX}px, ${bodyY}px)`,
                },
                rec: {
                    transform: `translate(${recX}px, ${recY}px)`,
                },
            };
        },
    },
    watch: {
        active() {
            this.$refs.scroll.scrollTop = 0;
        },
    },
    data: () => ({
        active: 0,
        mouse: {
            x: 0,
            y: 0,
        },
    }),
    methods: {
        handleMouse(ev) {
            const { x, y } = getInertedMouse(ev, this.mouse);

            this.mouse.x += x;
            this.mouse.y += y;
        },
    },
    mounted() {
        if (!this.$platform.isMobile) {
            document.body.addEventListener('mousemove', this.handleMouse);
        }
    },
    beforeDestroy() {
        if (!this.$platform.isMobile) {
            document.body.removeEventListener('mousemove', this.handleMouse);
        }
    },
};
</script>

<style lang="scss" scoped>
.expanded-items {
    width: 100%;

    .expanded-items-title {
        width: rem(300);
        margin: rem(22) var(--offset-x);
    }

    .data {
        width: 100%;
        max-width: 800px;
        height: rem(460);
        margin: rem(16) auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .expanded-items-nav {
            width: rem(64);
            position: absolute;
            top: 0;
            left: 0;
            transform: translate(-150%, 0);

            .nav-item {
                width: rem(64);
                height: rem(52);
                margin: rem(12) 0;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                font-size: rem(30);
                font-weight: 700;
                color: var(--violet);
                opacity: 0.5;
                transition: opacity 0.25s ease-in-quad;

                &::before {
                    content: '';
                    width: rem(24);
                    height: rem(26);
                    position: absolute;
                    top: 50%;
                    right: 125%;
                    background-image: url('../../../../../assets/images/articles/1/interview_active.svg');
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center center;
                    transform: translate(-12px, -50%);
                    opacity: 0;
                    transition: transform 0.3s ease-in-quad, opacity 0.25s ease-in-quad;
                }

                .icon {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1;
                    object-fit: cover;
                    opacity: 0;
                    transform-origin: 50% 50%;
                    transform: scale(0.7);
                    transition: transform 0.35s ease-in-quad, opacity 0.25s ease-in-quad;
                }

                &.active,
                &:hover,
                &:focus {
                    opacity: 1;
                    transition: opacity 0.25s ease-out-quad;
                }

                &.active {
                    &::before {
                        transform: translate(0, -50%);
                        opacity: 1;
                        transition: transform 0.4s ease-out-quad, opacity 0.25s ease-out-quad;
                    }

                    .icon {
                        opacity: 1;
                        transform: scale(1);
                        transition: opacity 0.25s ease-out-quad, transform 0.3s ease-out-quad;
                    }
                }
            }
        }

        .image-wrap {
            width: rem(376);
            height: rem(410);
            position: relative;

            .image {
                width: 100%;
                z-index: 1;
                object-fit: contain;

                &.body {
                    width: rem(290);
                    position: absolute;
                    top: 0;
                    right: 0;
                }

                &.rec {
                    width: rem(228);
                    position: absolute;
                    bottom: rem(-14);
                    right: rem(160);
                    z-index: 5;
                }
            }

            .image-decor {
                width: rem(130);
                height: rem(110);
                position: absolute;
                bottom: 0;
                right: 0;
                z-index: 10;
                transform: translate(30%, 30%);
            }
        }

        .question-answer {
            width: calc(47.5% - #{rem(36)});
            height: 100%;
            padding-right: rem(24);
            position: relative;
            overflow-y: auto;

            .question {
                margin-bottom: rem(12);
                padding-bottom: rem(12);
                position: sticky;
                top: 0;
                left: 0;
                background: url('../../../../../assets/images/noise.png') var(--color-main-bg);
                background-repeat: repeat;
                background-size: initial;
                background-position: 0 0;
            }
        }
    }

    @media (--viewport-tablet) {
        width: 100%;
        padding: 0 var(--offset-x);
        box-sizing: border-box;

        .expanded-items-title {
            width: 100%;
            margin: rem-mobile(32) 0;
        }

        .data {
            width: 100%;
            max-width: var(--default-max-width);
            height: rem-mobile(800);
            margin: rem-mobile(32) 0;
            flex-wrap: wrap;
            justify-content: center;
            align-content: flex-start;

            .expanded-items-nav {
                width: rem-mobile(48);
                top: rem-mobile(320);
                left: rem-mobile(18);
                z-index: 999;
                transform: translate(0%, 0);

                .nav-item {
                    width: rem-mobile(48);
                    height: rem-mobile(40);
                    margin: rem-mobile(12) 0;
                    font-size: rem-mobile(24);

                    &::before {
                        content: '';
                        width: rem-mobile(20);
                        height: rem-mobile(24);
                    }
                }
            }

            .image-wrap {
                width: rem-mobile(260);
                height: rem-mobile(320);
                margin: 0 auto;
                position: relative;
                order: 0;

                .image {
                    width: 100%;
                    z-index: 1;
                    object-fit: contain;

                    &.body {
                        width: rem-mobile(190);
                    }

                    &.rec {
                        width: rem-mobile(162);
                        bottom: rem-mobile(36);
                        left: rem-mobile(-12);
                        right: unset;
                    }
                }

                .image-decor {
                    width: rem-mobile(84);
                    height: rem-mobile(72);
                    transform: translate(25%, -30%);
                }
            }

            .question-answer {
                width: 100%;
                height: calc(100% - #{rem-mobile(320)});
                padding-left: rem-mobile(84);
                padding-right: rem-mobile(18);
                order: 1;

                .question {
                    margin-bottom: rem-mobile(12);
                    padding-bottom: rem-mobile(12);
                    position: static;
                }
            }
        }
    }
}
</style>
